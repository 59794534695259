import { getEnvironmentName } from './getEnvironmentName';

import type { DeployedEnvConfig } from '@tonkean/env';
import { loadEnvConfig } from '@tonkean/env/loadEnvConfig';

async function getDeployedEnvConfig(): Promise<DeployedEnvConfig> {
    const deployedEnvConfigResponse = await fetch('./deployed-env-config.json');
    const deployedEnvConfigResponseJson = (await deployedEnvConfigResponse.json()) as DeployedEnvConfig;

    const isLocalHost = window.location.hostname === 'localhost';

    if (isLocalHost && deployedEnvConfigResponseJson.defaultEnvironment !== 'local') {
        throw new Error(
            'Localhost should have local environment config - do not override deployed-env-config.json\n' +
                'If you need to override the environment, use the VITE_TONKEAN_ENV environment variable',
        );
    }

    return deployedEnvConfigResponseJson;
}

async function loadAppConfig(): Promise<void> {
    const deployedEnvConfig = await getDeployedEnvConfig();
    const environmentName = getEnvironmentName(deployedEnvConfig);

    console.log(`Loading environment config for ${environmentName}`);

    const config = await loadEnvConfig(environmentName);

    (typeof globalThis !== 'undefined' ? globalThis : window).ENV_CONFIG = config;
    (typeof globalThis !== 'undefined' ? globalThis : window).DEPLOYED_ENV_CONFIG = deployedEnvConfig;
}

export { loadAppConfig };
